import { Link } from "react-router-dom";

const services = [
    {
        iconClass: 'fa fa-laptop-code fa-2x',
        title: 'IT',
        description: 'Software development is the creation, testing, and maintenance of computer programs and applications.',
        link: 'https://roboanalytics.in/',
        delay: '0.1s'
    },
    {
        iconClass: 'fa fa-user-graduate fa-2x',
        title: 'Institution',
        description: 'Here, minds meet to delve into the depths of knowledge, explore new horizons, and innovate for the future.',
        link: 'https://abitm.com/',
        delay: '0.5s'
    },
    {
        iconClass: 'fas fa-hard-hat fa-2x',
        title: 'Ganapati Construction',
        description: 'Building structures, such as buildings, bridges, roads, and dams, through the use of materials, tools, and skilled labor.',
        link: '/construction',
        delay: '0.3s'
    },
    {
        iconClass: 'fa fa-hotel fa-2x',
        title: 'Hotel',
        description: 'Experience comfort and luxury at our boutique hotel, where impeccable service meets elegant design.',
        link: '/hotel',
        delay: '0.7s'
    }
];


export default function Feature(){
    return(
        <> <div className="container-fluid  feature" style={{backgroundColor:'#11409e'}}>
        <div className="container pt-5">
            <div className="row g-5">
                <div className="col-lg-6 align-self-center mb-md-5  wow fadeIn" data-wow-delay="0.3s">
                    {/* <div className="btn btn-sm border rounded-pill text-white px-3 mb-3">Why Choose Us</div> */}
                    <h1 className="text-white mb-4">We're Best in Bhattacharjee Group with 12 Years of Experience</h1>
                    <p className="text-light mb-4">Welcome to Bhattacharjee Group, where innovation meets excellence across diverse domains for over 12 years.

At Bhattacharjee Group, we pride ourselves on being a multifaceted organization operating in four key sectors:
</p>
                    <div className="d-flex align-items-center text-white mb-3">
                        <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i className="fa fa-check"></i>
                        </div>
                        <span> Ganapati Construction</span>
                    </div>
                    <div className="d-flex align-items-center text-white mb-3">
                        <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i className="fa fa-check"></i>
                        </div>
                        <span>Software Development</span>
                    </div>
                    <div className="d-flex align-items-center text-white mb-3">
                        <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i className="fa fa-check"></i>
                        </div>
                        <span>Institution</span>
                    </div>
                    <div className="d-flex align-items-center text-white mb-3">
                        <div className="btn-sm-square bg-white text-primary rounded-circle me-3">
                            <i className="fa fa-check"></i>
                        </div>
                        <span>Hotels</span>
                    </div>
                    <div className="row g-4 pt-5"> 
                        <div className="col-sm-6">
                            <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                                <i className="fa fa-users fa-3x text-white"></i>
                                <div className="ms-3">
                                    <h2 className="text-white mb-0" data-toggle="counter-up">199</h2>
                                    <p className="text-white mb-0">Happy Clients</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex rounded p-3" style={{background: "rgba(256, 256, 256, 0.1)"}}>
                                <i className="fa fa-check fa-3x text-white"></i>
                                <div className="ms-3">
                                    <h2 className="text-white mb-0" data-toggle="counter-up">199</h2>
                                    <p className="text-white mb-0">Project Complete</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
                    <img className="img-fluid" src="img/feature.png" alt=""/>
                </div> */}

                <div className="col-lg-6" style={{ marginBottom: "4%" }}>
            <div className="row g-4">
                {services.map((service, index) => (
                    <div key={index} className="col-md-6 wow fadeIn" data-wow-delay={service.delay}>
                        <div className="service-item d-flex flex-column justify-content-center text-center rounded" style={{ marginTop: "24px" }}>
                            <div className="service-icon btn-square">
                                <i className={service.iconClass}></i>
                            </div>
                            <h5 className="mb-3">{service.title}</h5>
                            <p>{service.description}</p>
                            <a className="btn px-3 mt-auto mx-auto" href={service.link}>Read More</a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
            </div>
        </div>
    </div></>
    )
}