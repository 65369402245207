
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BannerCard from "./BannerCard";
import { Link } from 'react-router-dom';

export default function Banner(){
    const BannerData = [
      
        {
            tag: 'BG Group',
            title: 'Robo Analytics',
            description: 'In an era defined by technological advancement, we harness the power of artificial intelligence to develop cutting-edge software solutions. Our team of skilled engineers and developers is committed to creating intelligent software that drives efficiency and innovation across industries.',
            image: 'img/baner2.webp',
            readMore:"https://roboanalytics.in/"
        },
        {
            tag: 'BG Group',
            title: 'Ganapati Construction',
            description: 'With a focus on quality craftsmanship and attention to detail, our construction division delivers projects that stand the test of time. From commercial buildings to residential complexes, we strive to exceed client expectations while adhering to the highest standards of safety and sustainability.',
            image: 'img/baner3.png',
            readMore:"/construction"
        },
        {
            tag: 'BG Group',
            title: 'ABITM Institute',
            description: 'Education is the cornerstone of progress, and our institute is dedicated to nurturing the next generation of leaders and innovators. Through a comprehensive range of courses and programs, we provide students with the knowledge and skills they need to thrive in an ever-evolving world.',
            image: 'img/baner5.png',
            readMore:"https://abitm.com/"
        },
       
     
        {
            tag: 'BG Group',
            title: 'Hotels',
            description: 'our hotel business, where we prioritize guest satisfaction above all else. Whether its a luxurious retreat or a cozy boutique hotel, we strive to create memorable experiences that leave a lasting impression.',
            image: 'img/baner4.png',
            readMore:"/hotel"
        },
       
    ]
    const options = {
        loop: true,
        center: true,
        items: 1,
        margin: 0,
        autoplay: true,
        dots: true,
        // autoplayTimeout: 8500,
        smartSpeed: 1000,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };
    return(
        <>
       <div className="container-fluid pt-5 position-relative   hero-header overlay-bottom"style={{ marginBottom: "80px", height: "752px" }}>
     
       <OwlCarousel className="owl-carousel  owl-theme " {...options}>
       {
            BannerData.length === 0 ?
                <div class="item">
                    <p>No data found</p>
                </div> :
                
                BannerData.map(BannerDetails => {
                    return (
                        <BannerCard BannerDetails2={BannerDetails} key={BannerDetails._key} />
                    )
                })
                
        }
        </OwlCarousel>
        </div> 
        
    {/* <div className="modal fade" id="searchModal" tabindex="-1">
      <div className="modal-dialog modal-fullscreen">
               <div className="modal-content" style={{background: "rgba(20, 24, 62, 0.7)"}}>
                <div className="modal-header border-0">
                    <button type="button" class="btn btn-square bg-white btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div> 
                 <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-light p-3"
                            placeholder="Type search keyword"/>
                        <button className="btn btn-light px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div> 
            </div>
        </div> 
    </div> */}
        </>
    )
}