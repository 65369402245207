import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function All_courses() {

    const Allcourses = [
        {
            img: "img/Institute/courses-1.jpg",
            title: "AI Machine Learning",
            instructor: "aariv",
            rating: 4.5,
            reviews: 250,
            link: "/course_detail"
        },
        {
            img: "img/Institute/courses-2.jpg",
            title: "Python Development",
            instructor: "Ravi",
            rating: 4.5,
            reviews: 250,
            link: "/course_detail"
        },
        {
            img: "img/Institute/courses-3.jpg",
            title: "UI/UX design",
            instructor: "Jhon Doe",
            rating: 4.5,
            reviews: 250,
            link: "/course_detail"
        },
        {
            img: "img/Institute/courses-4.jpg",
            title: "Digital Marketing",
            instructor: "Anshuman",
            rating: 4.5,
            reviews: 250,
            link: "/course_detail"
        },

    ];
    const options = {
        autoplay: false,
        smartSpeed: 1500,
        loop: false,
        dots: false,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            768: {
                items: 3
            },
            992: {
                items: 4
            }
        }
    };

    return (
        <>

            <div className=" bg-primary hero-header ">
                <div className="jumbotron jumbotron-fluid position-relative overlay-bottom" style={{ marginBottom: "50px", height: "550px" }}>
                    <div className="container pt-3 item">
                        <div className="row g-5 pt-5">
        
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>

                                <h2 className=" text-white mt-1  mb-3 animated slideInRight">Learn From ABITE Institute</h2>
                                <h1 className="display-3 text-white mb-5 animated slideInRight">All Courses</h1>
                                {/* <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">Explore All Courses </div> */}

                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <OwlCarousel className="owl-carousel owl-theme courses-carousel wow fadeIn" data-wow-delay="0.5s" {...options}>
                {Allcourses.map((course, index) => (
                    <div className="courses-item position-relative" key={index}>
                        <img className="img-fluid" src={course.img} alt={course.title} />
                        <div className="courses-text">
                            <h4 className="text-center text-white px-3">{course.title}</h4>
                            <div className="border-top w-100 mt-3">
                                <div className="d-flex justify-content-between p-4">
                                    <span className="text-white"><i className="fa fa-user mr-2"></i> {course.instructor}</span>
                                    <span className="text-white"><i className="fa fa-star mr-2"></i>{course.rating} <small>({course.reviews})</small></span>
                                </div>
                            </div>
                            <div className="w-100 bg-white text-center p-4">
                                <a className="btn btn-primary" href={course.link}>Course Detail</a>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </>
    )
}