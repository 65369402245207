import { Link } from "react-router-dom";

import { useEffect } from "react";
const scrollToTop = () => {
    window.scrollTo(0, 0);
};
export default function NavAbout(){
    useEffect(() => {
        scrollToTop();
    }, []);
    return(
        <>
     <div className="  hero-header ">
                <div class="jumbotron_about jumbotron-fluid position-relative overlay-bottom" style={{ height: "655px" }}>
                    <div className="container pt-5 item">
                        <div className="row g-5 pt-5">
                            {/* <h1 class="text-white mt-5 mb-4">Learn From Robo Analytics</h1>
            <h1 class="text-white display-1 mb-5">Education Courses</h1> */}
                            <div className="col-lg-12" style={{ textAlign: "center", marginTop: "178px" }}>
                                <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">BG GROUP</div>
                                <h2 className=" text-white mt-1  mb-3 animated slideInRight">Pushing Your Brand to the Next Level</h2>
                                <h1 className="display-3 text-white mb-5 animated slideInRight">About Bhattacharjee Group</h1>

                            </div>

                        </div>
                    </div>

                </div>
           </div>
        
         {/* <div className="modal fade" id="searchModal" tabindex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(20, 24, 62, 0.7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn btn-square bg-white btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-light p-3"
                            placeholder="Type search keyword"/>
                        <button className="btn btn-light px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
         </div> */}

<div className="container-fluid py-5 " >
        <div className="container ">
            <div className="row g-5   align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className=" about-img">
                        <img className=" img-fluid" src="img/about-img.jpg"/>
                    </div>
                </div>
                <div className="col-lg-6 wow fadeIn   " data-wow-delay="0.5s">
                    <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div>
                    <h1 className="mb-4">We Make Your Business Smarter with Artificial Intelligence</h1>
                    <p className="mb-4">Welcome to Bhattacharjee Group, a dynamic organization with over 12 years of experience spanning multiple domains. From pioneering AI and software development solutions to delivering top-notch construction projects, providing quality education through our institute, and offering unparalleled hospitality at our hotels, we are committed to excellence in every endeavor. Our dedication to innovation, quality, and customer satisfaction sets us apart, making Bhattacharjee Group your trusted partner for success.</p>
                    {/* <p className="mb-4">We specialize in crafting customized digital solutions, spanning AI, IoT, mobile apps, and cloud expertise, addressing the unique needs of businesses.</p> */}
                    <div className="row g-3">
                        <div className="col-sm-6">
                            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Award Winning</h6>
                            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                        </div>
                        <div className="col-sm-6">
                            <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                            <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-4">
                        {/* <Link className="btn btn-primary rounded-pill px-4 me-3" to="#">Read More</Link> */}
                        <Link className="btn btn-outline-primary btn-square me-3" to="#"><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-outline-primary btn-square me-3" to="#"><i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-outline-primary btn-square me-3" to="#"><i className="fab fa-instagram"></i></Link>
                        <Link className="btn btn-outline-primary btn-square" to="#"><i className="fab fa-linkedin-in"></i></Link>
                    </div>
                </div>
            </div>
        </div>
         </div>
    </>
    )
}


// import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { Search, GpsFixed } from '@material-ui/icons';

// const apiKey = import.meta.env.VITE_APP_GMAP_API_KEY;
// const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
// const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// // load google map api js
// function loadAsyncScript(src) {
//   return new Promise(resolve => {
//     const script = document.createElement('script');
//     Object.assign(script, {
//       type: 'text/javascript',
//       async: true,
//       src,
//     });
//     script.addEventListener('load', () => resolve(script));
//     document.head.appendChild(script);
//   });
// }

// const extractAddress = (place) => {
//   const address = {
//     city: '',
//     state: '',
//     zip: '',
//     country: '',
//     plain() {
//       const city = this.city ? this.city + ', ' : '';
//       const zip = this.zip ? this.zip + ', ' : '';
//       const state = this.state ? this.state + ', ' : '';
//       return city + zip + state + this.country;
//     },
//   };

//   if (!Array.isArray(place?.address_components)) {
//     return address;
//   }

//   place.address_components.forEach(component => {
//     const types = component.types;
//     const value = component.long_name;

//     if (types.includes('locality')) {
//       address.city = value;
//     }

//     if (types.includes('administrative_area_level_1')) {
//       address.state = value;
//     }

//     if (types.includes('postal_code')) {
//       address.zip = value;
//     }

//     if (types.includes('country')) {
//       address.country = value;
//     }
//   });

//   return address;
// };

// function Map() {
//   const searchInput = useRef(null);
//   const [address, setAddress] = useState({});

//   // init gmap script
//   const initMapScript = useCallback(() => {
//     if (window.google) {
//       return Promise.resolve();
//     }
//     const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
//     return loadAsyncScript(src);
//   }, []);

//   // do something on address change
//   const onChangeAddress = useCallback((autocomplete) => {
//     const place = autocomplete.getPlace();
//     setAddress(extractAddress(place));
//   }, []);

//   // init autocomplete
//   const initAutocomplete = useCallback(() => {
//     if (!searchInput.current) return;

//     const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
//     autocomplete.setFields(['address_component', 'geometry']);
//     autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
//   }, [onChangeAddress]);

//   const reverseGeocode = useCallback(({ latitude: lat, longitude: lng }) => {
//     const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
//     searchInput.current.value = 'Getting your location...';
//     fetch(url)
//       .then(response => response.json())
//       .then(location => {
//         if (location.results && location.results[0]) {
//           const place = location.results[0];
//           const _address = extractAddress(place);
//           setAddress(_address);
//           searchInput.current.value = _address.plain();
//         } else {
//           searchInput.current.value = 'Location not found';
//         }
//       })
//       .catch(() => {
//         searchInput.current.value = 'Failed to retrieve location';
//       });
//   }, []);

//   const findMyLocation = useCallback(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         position => {
//           reverseGeocode(position.coords);
//         },
//         () => {
//           searchInput.current.value = 'Unable to retrieve your location';
//         }
//       );
//     } else {
//       searchInput.current.value = 'Geolocation is not supported by this browser';
//     }
//   }, [reverseGeocode]);

//   // load map script after mounted
//   useEffect(() => {
//     initMapScript().then(() => initAutocomplete());
//   }, [initMapScript, initAutocomplete]);

//   return (
//     <div className="App">
//       <div>
//         <div className="search">
//           <span>
//             <Search />
//           </span>
//           <input ref={searchInput} type="text" placeholder="Search location...." />
//           <button onClick={findMyLocation}>
//             <GpsFixed />
//           </button>
//         </div>

//         <div className="address">
//           <p>
//             City: <span>{address.city}</span>
//           </p>
//           <p>
//             State: <span>{address.state}</span>
//           </p>
//           <p>
//             Zip: <span>{address.zip}</span>
//           </p>
//           <p>
//             Country: <span>{address.country}</span>
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Map;






