import React, { useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from "react-router-dom";

export default function Property() {

    const [activeIndex, setActiveIndex] = useState(null);
    const [activeTab, setActiveTab] = useState('hospital');

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const Nearbyplaces = {
        hospital: [
            { name: 'Massachusetts General Hospital', distance: '23.7 km', type: 'Medical Collage' },
            { name: 'Langone Medical Center', distance: '13.2 km', type: 'Hart Hospital' },
            { name: 'Mount Sinai Hospital', distance: '58.0 km', type: 'Eye Hospital' }
        ],
        shopping: [
            { name: 'Mall A', distance: '10.0 km', type: 'Shopping Mall' },
            { name: 'Plaza B', distance: '15.5 km', type: 'Shopping Plaza' },
            { name: 'Center C', distance: '20.0 km', type: 'Shopping Center' }
        ],
        school: [
            { name: 'School X', distance: '5.0 km', type: 'Elementary School' },
            { name: 'School Y', distance: '8.5 km', type: 'High School' },
            { name: 'School Z', distance: '12.0 km', type: 'University' }
        ],
        restaurant: [
            { name: 'Restaurant 1', distance: '2.5 km', type: 'Italian' },
            { name: 'Restaurant 2', distance: '3.0 km', type: 'Chinese' },
            { name: 'Restaurant 3', distance: '5.0 km', type: 'Indian' }
        ]
    };

    const renderTable = (category) => (
        <table className="table w-100 text-secondary">
            <thead>
                <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Distance</th>
                    <th scope="col">Type</th>
                </tr>
            </thead>
            <tbody>
                {Nearbyplaces[category].map((Nearbyplaces, index) => (
                    <tr key={index}>
                        <td>{Nearbyplaces.name}</td>
                        <td>{Nearbyplaces.distance}</td>
                        <td>{Nearbyplaces.type}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <>
            <div class="full-row bg-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">


                            <div className="row">
                                <div className="col-md-12">
                                    <div id="single-property" style={{ width: '1200px', height: '700px', margin: '0 auto 50px' }}>
                                        <Carousel
                                            autoPlay
                                            interval={3000}
                                            infiniteLoop
                                            showThumbs={true}
                                            showStatus={true}
                                            showIndicators
                                            dynamicHeight={true}
                                            stopOnHover
                                            useKeyboardArrows
                                        // transitionTime={1000}
                                        >
                                            <div>
                                                <img src="img/Construction/01.jpg" alt="" />
                                            </div>
                                            <div>
                                                <img src="img/Construction/02.jpg" alt="" />
                                            </div>
                                            <div>
                                                <img src="img/Construction/03.jpg" alt="" />
                                            </div>
                                        </Carousel>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-5">
                                <div class="col-md-6">
                                    <div class="bg-primary d-table px-3 py-2 rounded text-white">For Sale</div>
                                    <h5 class="mt-2 text-secondary">Arun Villa</h5>
                                    <span class="d-block"><i class="fas fa-map-marker-alt text-primary font-12"></i> 26 Binodini Avenue, Hatiara Road, Kolkata</span>
                                </div>
                                <div class="col-md-6"> <span class="d-block text-start text-secondary text-md-end"><i class="far fa-star"></i></span>
                                    <div class="text-primary text-start h5 my-2 text-md-end">₹352,000</div>
                                    <div class="text-start text-md-end">Fixed Amount</div>
                                </div>
                            </div>

                            <div class="row">

                                <div class="col-lg-12 order-lg-1">
                                    <div class="property-details mt-md-30">
                                        <div class="row">


                                            <div class="col-md-4">
                                                <div class="property-quantity px-4 pt-4">
                                                    <ul>
                                                        <li><span class="text-secondary">1BHK</span>660 sqft</li>
                                                        <li><span class="text-secondary">Rooms</span>2 </li>
                                                        <li><span class="text-secondary">Baths</span> 1</li>
                                                        <li><span class="text-secondary">Parking</span> 1</li>
                                                        <li><span class="text-secondary">Kitchen</span> large</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="property-quantity px-4 pt-4">
                                                    <ul>
                                                        <li><span class="text-secondary">2BHK</span>880 sqft</li>
                                                        <li><span class="text-secondary">Rooms</span>3 </li>
                                                        <li><span class="text-secondary">Baths</span> 2</li>
                                                        <li><span class="text-secondary">Parking</span> 1</li>
                                                        <li><span class="text-secondary">Kitchen</span> large</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="property-quantity px-4 pt-4">
                                                    <ul>
                                                        <li><span class="text-secondary">Total Floor</span>5</li>
                                                        <li><span class="text-secondary">Insurance </span>Yes </li>

                                                        <li><span class="text-secondary">Type</span> Appartment</li>
                                                        <li><span class="text-secondary">Alivator</span> Yes</li>
                                                        <li><span class="text-secondary">Loan</span> Yes</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col">
                                                <h4 class="text-secondary my-4">Description</h4>
                                                <p>Bibendum purus aenean mus aenean eu interdum nonummy ipsum ad consequat. Dui eros donec faucibus convallis tempus rutrum id donec mus hymenaeos placerat congue curae mauris turpis gravida viverra consequat consequat
                                                    gravida luctus.</p>
                                                <p class="mt-30">Consectetuer aliquet. Libero porttitor curabitur vivamus accumsan placerat mattis, in lobortis auctor dolor mus, morbi. Dictumst dictumst. Faucibus. Est mollis. Turpis tortor. In vivamus venenatis neque hendrerit
                                                    risus amet auctor cras, varius augue nullam morbi posuere lacus porttitor dictumst tincidunt curabitur ilisis torquent magnis cras maecenas vel. Odio proin, aptent tristique urna, nibh iaculis auctor Arcu
                                                    faucibus sollicitudin donec inceptos dapibus viverra. Lorem consequat. Ac viverra torquent.</p>
                                                <a class="text-primary hover-text-secondary mt-4 mb-4 ps-3 position-relative plus-minus d-block" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">More Details</a>
                                                <div class="collapse" id="multiCollapseExample1">
                                                    <p>Hymenaeos class nullam pretium cras tristique orci tincidunt, dapibus. Blandit phasellus ligula. Elit senectus posuere commodo in semper, et placerat vehicula ante. Vehicula dui bibendum curabitur. Scelerisque
                                                        mus mattis elit dolor suspendisse gravida. Faucibus, viverra feugiat dignissim dis. Nulla accumsan nonummy a semper donec rhoncus hendrerit, mi gravida eget nulla neque torquent parturient facilisis
                                                        praesent enim mus sem. Cubilia. Suscipit. Senectus curae; id augue dapibus sagittis tempor. Cursus. Tortor montes imperdiet sollicitudin tristique consequat. Pellentesque conubia euismod proin convallis
                                                        donec torquent elit nisi eget aliquam ipsum lorem convallis velit sapien augue tempor ligula aliquet euismod eget nisl justo urna suscipit.</p>
                                                    <p>Purus morbi sociis. Torquent montes convallis magna potenti gravida laoreet id. Nam vehicula aliquam massa suspendisse in facilisi in pharetra eget justo viverra, posuere. Sed interdum euismod adipiscing
                                                        lobortis massa risus ad enim lectus magnis lacinia ultricies dolor curabitur gravida, potenti dapibus est hymenaeos maecenas. Scelerisque vehicula. Turpis lacus pellentesque nisl platea urna ut sapien
                                                        semper mus amet aptent. Volutpat nam nascetur commodo vitae maecenas augue feugiat, sem interdum molestie ultricies dictumst. Ante suscipit. Netus ultrices class mollis elit potenti auctor penatibus.
                                                        Commodo per. Praesent torquent, rhoncus porttitor vitae sem platea, sed dapibus facilisi nam convallis, tristique eleifend taciti ultricies elit varius. Ipsum habitant senectus turpis magna nostra lacus
                                                        nisl aliquam pharetra pellentesque, eros ullamcorper. Hymenaeos sit laoreet ante per convallis ornare posuere, cum vitae elementum fusce amet. Eget. At laoreet. Molestie sit ac. Vestibulum quis ipsum
                                                        neque curabitur convallis Convallis nisi adipiscing ligula. Imperdiet habitasse condimentum, sed potenti accumsan tristique cras tempus. Consectetuer curae; ad consequat platea aliquet est enim dis justo
                                                        justo torquent eget pede malesuada curae; scelerisque dolor Cras natoque commodo molestie elit mauris conubia ad ultrices mus cum dictum. Quisque urna aliquam, ridiculus curae; egestas turpis aptent
                                                        elit curabitur ipsum. Euismod rhoncus orci vestibulum euismod nunc.</p>
                                                </div>
                                            </div>
                                        </div>





                                        <div className="row">
                                            <div className="col">
                                                <h5 className="mt-5 mb-4 text-secondary">Floor Plans</h5>
                                                <div className="accordion mt-4" id="accordionExample">
                                                    <div className="accordion-item border-0">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button
                                                                className={`accordion-button bg-gray ${activeIndex === 0 ? '' : 'collapsed'}`}
                                                                type="button"
                                                                onClick={() => handleToggle(0)}
                                                                aria-expanded={activeIndex === 0}
                                                                aria-controls="collapseOne"
                                                            >
                                                                Floor Plans [ 4200 sqft ]
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="collapseOne"
                                                            className={`accordion-collapse collapse ${activeIndex === 0 ? 'show' : ''}`}
                                                            aria-labelledby="headingOne"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="p-4">
                                                                <ul className="d-inline-block">
                                                                    <li className="float-start me-3"><span className="text-secondary">Bed: </span>162.5 sqft</li>
                                                                    <li className="float-start me-3"><span className="text-secondary">Kitchen: </span>108.2 sqft</li>
                                                                    <li className="float-start me-3"><span className="text-secondary">Dining: </span>145.7 sqft</li>
                                                                    <li className="float-start me-3"><span className="text-secondary">Bath: </span>38.7 sqft</li>
                                                                </ul>
                                                                <img src="img/Construction/portfolio-1.jpg" alt="Floor Plan" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Add more accordion items as needed */}
                                                </div>

                                                <div className="accordion mt-4" id="accordionExample">
                                                    <div className="accordion-item border-0">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button
                                                                className={`accordion-button bg-gray ${activeIndex === 1 ? '' : 'collapsed'}`}
                                                                type="button"
                                                                onClick={() => handleToggle(1)}
                                                                aria-expanded={activeIndex === 1}
                                                                aria-controls="collapseTwo"
                                                            >
                                                                Garage Plan [ 200 sqft ]
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="collapseTwo"
                                                            className={`accordion-collapse collapse ${activeIndex === 1 ? 'show' : ''}`}
                                                            aria-labelledby="headingTwo"
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="p-4">
                                                                <ul className="d-inline-block">
                                                                    <li className="float-start me-3"><span className="text-secondary">Bed: </span>162.5 sqft</li>
                                                                    <li className="float-start me-3"><span className="text-secondary">Kitchen: </span>108.2 sqft</li>
                                                                    <li className="float-start me-3"><span className="text-secondary">Dining: </span>145.7 sqft</li>
                                                                    <li className="float-start me-3"><span className="text-secondary">Bath: </span>38.7 sqft</li>
                                                                </ul>
                                                                <img src="img/Construction/portfolio-1.jpg" alt="Floor Plan" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Add more accordion items as needed */}
                                                </div>
                                            </div>


                                        </div>



                                        <div class="row">
                                            <div class="col">
                                                <h5 class="mt-5 mb-4 text-secondary">Property Video</h5>

                                                <div class="container-xxl py-5 px-0 wow zoomIn" data-wow-delay="0.1s">
                                                    <div class="row g-0">

                                                        <div class="col-md-12">
                                                            <div class="video-property ">
                                                                <button type="button" class="btn-play" data-bs-toggle="modal" data-src="https://youtu.be/Kz3W_H3H92A?si=sShK0YJIb5Eg0njZ" data-bs-target="#videoModal">
                                                                    <span></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content rounded-0">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="exampleModalLabel">Arun Villa</h5>
                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">

                                                                <div class="ratio ratio-16x9">
                                                                    <video id="video" controls>
                                                                        <source src="img/Construction/video5.mp4" type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5 className="mt-5 mb-5 text-secondary">Nearby Places</h5>
                                            </div>
                                            <div className="col-md-10">
                                                <ul className="nav nav-pills mb-3 bg-gray">
                                                    <li className="nav-item">
                                                        <Link

                                                            className={`nav-link py-3 ${activeTab === 'hospital' ? 'active' : ''}`}
                                                            onClick={() => setActiveTab('hospital')}
                                                        >
                                                            Hospital
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link

                                                            className={`nav-link py-3 ${activeTab === 'shopping' ? 'active' : ''}`}
                                                            onClick={() => setActiveTab('shopping')}
                                                        >
                                                            Shopping
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link

                                                            className={`nav-link py-3 ${activeTab === 'school' ? 'active' : ''}`}
                                                            onClick={() => setActiveTab('school')}
                                                        >
                                                            School
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link

                                                            className={`nav-link py-3 ${activeTab === 'restaurant' ? 'active' : ''}`}
                                                            onClick={() => setActiveTab('restaurant')}
                                                        >
                                                            Restaurant
                                                        </Link>
                                                    </li>
                                                </ul>
                                                <div className="tab-content mt-4">
                                                    <div className={`tab-pane fade show ${activeTab === 'hospital' ? 'active' : ''}`} id="pills-hospital">
                                                        <div className="overflow-x-scroll pb-3">
                                                            {renderTable('hospital')}
                                                        </div>
                                                    </div>
                                                    <div className={`tab-pane fade show ${activeTab === 'shopping' ? 'active' : ''}`} id="pills-shopping">
                                                        <div className="overflow-x-scroll pb-3">
                                                            {renderTable('shopping')}
                                                        </div>
                                                    </div>
                                                    <div className={`tab-pane fade show ${activeTab === 'school' ? 'active' : ''}`} id="pills-school">
                                                        <div className="overflow-x-scroll pb-3">
                                                            {renderTable('school')}
                                                        </div>
                                                    </div>
                                                    <div className={`tab-pane fade show ${activeTab === 'restaurant' ? 'active' : ''}`} id="pills-restaurant">
                                                        <div className="overflow-x-scroll pb-3">
                                                            {renderTable('restaurant')}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/*                                    

                                    <div class="row">
                                        <div class="col">
                                            <h5 class="mt-5 mb-4 text-secondary">Property Location</h5>
                                            <div id="map" class="single-map"></div>
                                        </div>
                                    </div>

                                    */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}