import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { IoMdClose } from "react-icons/io";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '550px'
};

const mapStyle = {
    flex: 1,
    borderRadius: '12px',
    border: '1px solid gainsboro',
    overflow: 'hidden'
};

const center = {
    lat: -3.745,
    lng: -38.523
};

const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export default function Map() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCXCLOlAn0HvXDn4osLp2MJB0Lf0qPFDb8"
    });

    const [map, setMap] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImgUrl, setSelectedImgUrl] = useState(null);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const onLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        points.forEach(point => bounds.extend(new window.google.maps.LatLng(point.lat, point.lng)));
        map.fitBounds(bounds);
        setMap(map);
    };

    const onUnmount = () => {
        setMap(null);
    };

    const handleClick = (id) => {
        console.log(`id ${id} clicked`);
        const selected = points.find(point => point.id === id);
        setSelectedItem(selected);
        setModalOpen(true); // Open the modal when a marker is clicked
    };

    const handleMouseEnter = (id) => {
        setHoveredItemId(id);

        // Scroll to the corresponding item in the list
        const element = document.getElementById(`item-${id}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const handleMouseLeave = () => {
        setHoveredItemId(null);
        setActiveMarker(null);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedItem(null);
    };

    const getMarkerIcon = (pointId) => {
        const size = hoveredItemId === pointId ? 60 : 40; // Adjust size as needed
        const url = hoveredItemId === pointId ? '/img/Construction/map1.png' : '/img/Construction/map.png';
        return {
            url: url,
            scaledSize: new window.google.maps.Size(size, size)
        };
    };

    useEffect(() => {
        scrollToTop();
    }, []);



    const points = [
        {
            id: 1,
            lat: -3.745,
            lng: -38.526,
            title: 'MAA Tower',
            location: 'Shitala Tala, Hatiara Road, Kolkata',
            imageSrc: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc1: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc2: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc3: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            description: 'Description for MAA Tower'
        },
        {
            id: 2,
            lat: -3.743,
            lng: -38.522,
            title: 'Arun Villa',
            location: '26 Binodini Avenue, Hatiara Road, Kolkata',
            imageSrc: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc1: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc2: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc3: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            description: 'Description for Arun Villa'
        },
        {
            id: 3,
            lat: -3.744,
            lng: -38.529,
            title: 'Mamta Villa',
            location: 'Kestopur Vip Road, Kolkata',
            imageSrc: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc1: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc2: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc3: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            description: 'Description for Mamta Villa'
        },
        {
            id: 4,
            lat: -3.746,
            lng: -38.525,
            title: 'Sonali Tower',
            location: 'Chinar Park, Newtown, Kolkata',
            imageSrc: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc1: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc2: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc3: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            description: 'Description for Sonali Tower'
        },
        {
            id: 5,
            lat: -3.748,
            lng: -38.528,
            title: 'Upcoming Project',
            location: 'Near City Center 2, Kolkata',
            imageSrc: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc1: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc2: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc3: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            description: 'Description for Upcoming Project'
        },
        {
            id: 6,
            lat: -3.742,
            lng: -38.521,
            title: 'Upcoming Project',
            location: 'Binodini Avenue, Hatiara, Kolkata',
            imageSrc: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc1: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc2: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            imageSrc3: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/169963/photo-1429043794791-eb8f26f44081.jpeg',
            description: 'Description for Upcoming Project'
        }
    ];

    return (
        <div className="row" style={{ padding: "25px", marginBottom: '4%' }}>
            {isLoaded ? (
                <>
                    <div className="col-6">
                        <div style={containerStyle}>
                            <GoogleMap
                                mapContainerStyle={mapStyle}
                                center={center}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                {points.map((point) => (
                                    <Marker
                                        key={point.id}
                                        position={{ lat: point.lat, lng: point.lng }}
                                        onClick={() => handleClick(point.id)}
                                        icon={getMarkerIcon(point.id)}
                                        onMouseOver={() => handleMouseEnter(point.id)}
                                        onMouseOut={handleMouseLeave}
                                    >
                                        {hoveredItemId === point.id && (
                                            <InfoWindow>
                                                <div style={{ maxWidth: '200px', maxHeight: '120px', display: 'flex', flexDirection: 'row', borderRadius: '8px' }}>
                                                    <img src={point.imageSrc} alt={point.title} style={{ maxWidth: '50%', borderRadius: '8px 0 0 8px' }} />
                                                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '8px', borderRadius: '0 8px 8px 0' }}>
                                                        <h6>{point.title}</h6>
                                                        <p>{point.location}</p>
                                                    </div>
                                                </div>
                                            </InfoWindow>
                                        )}
                                    </Marker>
                                ))}
                            </GoogleMap>
                        </div>
                    </div>

                    <div className="post_container col-md-6">

                        {points.map((item) => (
                            <div key={item.id} className="column" id={`item-${item.id}`}>
                                <div
                                    className={`post-module  ${hoveredItemId == item.id ? 'hover' : ''}`}
                                    onMouseEnter={() => handleMouseEnter(item.id)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <Carousel className='map_carousel'>

                                        <div className="thumbnail">
                                            <img src={item.imageSrc} alt="Post Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </div>
                                        <div className="thumbnail">
                                            <img src={item.imageSrc1} alt="Post Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </div>
                                        <div className="thumbnail">
                                            <img src={item.imageSrc2} alt="Post Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </div>
                                        <div className="thumbnail">
                                            <img src={item.imageSrc3} alt="Post Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </div>

                                    </Carousel>
                                    <div className="post-content">
                                        <div className="category">{item.title}</div>
                                        <h1 className="title">{item.location}</h1>
                                        <h2 className="sub_title">{item.subtitle}</h2>
                                        <p className="description">{item.description}</p>
                                        <div className="post-meta">
                                            <span className="timestamp">
                                                <i className="fa fa-clock-o">5 min</i> {item.timestamp}
                                            </span>
                                            <span className="comments">
                                                <i className="fa fa-comments">wow</i> {item.comments}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : null}

            {/* Modal */}
            {/* <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 450,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center',
                    borderRadius: "10px"
                }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <IoMdClose />
                    </IconButton>
                    <Typography id="modal_post_title" variant="h6" component="h2">
                        {selectedItem && (
                            <div className="category">{selectedItem.title}</div>
                        )}
                    </Typography>
                    {selectedItem && (
                        <div className="modal_post_container">
                            <div key={selectedItem.id} className="column">
                                <div className={`post-module `}>

                                    <Carousel>

                                        <div className="thumbnail">
                                            <img src={selectedItem.imageSrc} alt="Post Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </div>
                                        <div className="thumbnail">
                                            <img src={selectedItem.imageSrc1} alt="Post Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </div>
                                        <div className="thumbnail">
                                            <img src={selectedItem.imageSrc2} alt="Post Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </div>
                                        <div className="thumbnail">
                                            <img src={selectedItem.imageSrc3} alt="Post Thumbnail" style={{ maxWidth: '100%', height: 'auto' }} />
                                        </div>

                                    </Carousel>
                                    <div className="post-content">

                                        <h1 className="title">{selectedItem.location}</h1>
                                        <h2 className="sub_title">{selectedItem.subtitle}</h2>
                                        <p className="description">{selectedItem.description}</p>
                                        <div className="post-meta">
                                            <span className="timestamp">
                                                <i className="fa fa-clock-o">5 min</i> {selectedItem.timestamp}
                                            </span>
                                            <span className="comments">
                                                <i className="fa fa-comments">wow</i> {selectedItem.comments}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Box>
            </Modal> */}
        </div>
    );
}

