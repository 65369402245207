import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useState, useEffect } from 'react';
import About from "../../component/About";
const scrollToTop = () => {
    window.scrollTo(0, 0);
};
export default function It() {
    useEffect(() => {
        scrollToTop();
    }, []);
    const testimonials = [
        {
            text: "Hey I wanted to leave my review, I had some work done and the work was great and communication was good.",
            name: "Taylor Smith",
            profession: "Profession",
            imgSrc: "img/It/testimonial-1.jpg"
        },
        {
            text: "From the beginning, Robo Analytics team was very professional. They answered all my questions.",
            name: "Dark",
            profession: "Profession",
            imgSrc: "img/It/testimonial-2.jpg"
        },
        {
            text: "always was very responsive on email/Skype and was a pleasure to work with.",
            name: "Tom",
            profession: "Profession",
            imgSrc: "img/It/testimonial-3.jpg"
        },
        {
            text: "I will be doing more work with him and his team. They answered all my questions.",
            name: "Alexa",
            profession: "Profession",
            imgSrc: "img/It/testimonial-4.jpg"
        }
    ];

    const testimonial_carousel = ({
        autoplay: true,
        smartSpeed: 1000,
        margin: 25,
        dots: false,
        loop: true,
        center: true,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            }
        }
    });
    const [filter, setFilter] = useState("*"); // Default filter is set to show all items
    const [filteredItems, setFilteredItems] = useState([]);

    const services = [
        {
            title: "AI Research and Development",
            icon: "fa-robot",
            description: "AI tool and software development involves creating intelligent systems and applications that can perform tasks traditionally requiring human intelligence",
            link: "/ai",
            delay: "0.3s"
        },
        {
            title: "Cloud Solutions",
            icon: "fa-cloud",
            description: "We Deliver Cloud Solutions across major cloud platforms such as IBM Cloud, Google Cloud AI, Azure, and AWS.",
            link: "/cloud_solutions",
            delay: "0.6s"
        },
        {
            title: "Mobile App Development",
            icon: "fa-mobile",
            description: "Mobile app development involves the creation of software applications specifically designed to run on mobile devices such as smartphones and tablets.",
            link: "/mobile_app_development",
            delay: "0.6s"
        },
        {
            title: "Web & Software Development",
            icon: "fa-desktop",
            description: "Our web development team makes sure that they stay up-to-date with the latest technological developments in this area so as to offer the best solutions in terms of technology.",
            link: "/web_development",
            delay: "0.1s"
        },
        {
            title: "UX/UI Design",
            icon: "fa-laptop-code",
            description: "It has turned our UX/UI designers into experts in creating the most elegant and appealing UX Web Design.",
            link: "/ui_ux_design",
            delay: "0.1s"
        },
        {
            title: "Digital Marketing",
            icon: "fa-bullhorn",
            description: "Digital Marketing is all about creating an online presence and reaching out to your consumer’s screen to sell your products and services.",
            link: "/digital_marketing",
            delay: "0.3s"
        }
    ];
    

    // Sample portfolio items
    const portfolioItems = [
        { name: "Project 1", category: "first", image: "img/It/portfolio-1.jpg" },
        { name: "Project 2", category: "second", image: "img/It/portfolio-2.jpg" },
        { name: "Project 3", category: "first", image: "img/It/portfolio-3.jpg" },
        { name: "Project 4", category: "second", image: "img/It/portfolio-4.jpg" },
        { name: "Project 5", category: "first", image: "img/It/portfolio-5.jpg" },
        { name: "Project 6", category: "second", image: "img/It/portfolio-6.jpg" },
    ];

    const teamMembers = [
        {
            // name: "Abhishek Bhattacharjee",
            title: "Founder & CEO",
            imgSrc: "img/It/ceo3.png",
            delay: "0.1s",
            social: {
                facebook: "#",
                twitter: "#",
                instagram: "#",
                linkedin: "#"
            }
        },
        {
            // name: "Rahul Shaw",
            title: "AI Research Head",
            imgSrc: "img/It/team1.png",
            delay: "0.3s",
            social: {
                facebook: "#",
                twitter: "#",
                instagram: "#",
                linkedin: "#"
            }
        },
        {
            // name: "Sumit Prasad",
            title: "Delivery Head ",
            imgSrc: "img/It/team2.jpg",
            delay: "0.6s",
            social: {
                facebook: "#",
                twitter: "#",
                instagram: "#",
                linkedin: "#"
            }
        }
    ];


    // Function to handle filter selection
    const handleFilter = (category) => {
        setFilter(category);
    }

    useEffect(() => {
        if (filter === "*") {
            setFilteredItems(portfolioItems);
        } else {
            const filtered = portfolioItems.filter(item => item.category === filter);
            setFilteredItems(filtered);
        }
    }, [filter]);


    return (
        <>
            <div className="container-fluid pt-5 position-relative   hero-header overlay-bottom" style={{ marginBottom: "50px", height: "752px" }}>
                <div className="container pt-5 item">
                    <div className="row g-5 pt-5">
                        <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
                            <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">BG GROUP</div>
                            <h1 className="display-4 text-white mb-4 animated slideInRight">Robo Analytics</h1>
                            <p className="text-white mb-4 animated slideInRight">In an era defined by technological advancement, we harness the power of artificial intelligence to develop cutting-edge software solutions. Our team of skilled engineers and developers is committed to creating intelligent software that drives efficiency and innovation across industries.</p>

                        </div>
                        <div className="col-lg-6 align-self-end text-center text-lg-end">
                            <img className="img-fluids" src="../img/baner2.webp" alt="" />
                        </div>
                    </div>
                </div>
            </div>

            {/* about */}


            {/* <div className="container-xxl py-5">
                <div className="container-fluid px-lg-5">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="section-title position-relative mb-4 pb-2">
                                <h6 className="position-relative text-primary ps-4">About Us</h6>
                                <h2 className="mt-2">The best Software solution with 12 years of experience</h2>
                            </div>
                            <p className="mb-4">We offer you to plunge into the world of Robo Analytics through our partnership program.  Robo Analytics ensures the quality and core values of software industry, so if you are a Business Agency/Contractor then shake us a hand to accelerate your work with our expert resources.</p>
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Award Winning</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <a className="btn btn-primary rounded-pill px-4 me-3" to="">Read More</a>
                                <a className="btn btn-outline-primary btn-square me-3" to=""><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-primary btn-square me-3" to=""><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-outline-primary btn-square me-3" to=""><i className="fab fa-instagram"></i></a>
                                <a className="btn btn-outline-primary btn-square" to=""><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/It/about.jpg" />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid py-5 " >
                <div className="container ">
                    <div className="row g-5   align-items-center">
                        <div className="col-lg-6  wow zoomIn" data-wow-delay="0.5s">
                            <div className=" about-img">
                                <img className=" img-fluid" src="img/about-img.jpg" />
                            </div>
                        </div>
                        <div className="col-lg-6 fadeInUp" data-wow-delay="0.2s">
                            {/* <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div> */}
                            <h1 className="mb-4">Revolutionize Business with AI and Cloud Solutions</h1>
                            <p className="mb-4">Robo Analytics is a pioneering technology firm with 12 years of industry expertise, specializing in AI research and development, sophisticated AI tools and software, and comprehensive cloud solutions across IBM Cloud, Google Cloud AI, Azure, and AWS. Our expert team excels in developing high-performance mobile and web applications, utilizing the latest technologies and frameworks. We are dedicated to creating intuitive and visually stunning UX/UI designs that enhance user engagement. Leveraging advanced machine learning and artificial intelligence, we transform complex data into actionable insights. Our robust digital marketing strategies amplify your online presence, driving targeted traffic and maximizing ROI. At Robo Analytics, we provide customized, cutting-edge solutions designed to propel your business into the future.</p>
                            {/* <p className="mb-4">We specialize in crafting customized digital solutions, spanning AI, IoT, mobile apps, and cloud expertise, addressing the unique needs of businesses.</p> */}
                            <div className="row g-3">
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Award Winning</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Professional Staff</h6>
                                </div>
                                <div className="col-sm-6">
                                    <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                                    <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Fair Prices</h6>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mt-4">
                                <Link className="btn btn-primary rounded-pill px-4 me-3" to="#">Read More</Link>
                                <Link className="btn btn-outline-primary btn-square me-3" to="#"><i className="fab fa-facebook-f"></i></Link>
                                <Link className="btn btn-outline-primary btn-square me-3" to="#"><i className="fab fa-twitter"></i></Link>
                                <Link className="btn btn-outline-primary btn-square me-3" to="#"><i className="fab fa-instagram"></i></Link>
                                <Link className="btn btn-outline-primary btn-square" to="#"><i className="fab fa-linkedin-in"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Service Start */}

            <div className="container-xxl py-5">
            <div className="container px-lg-5">
                <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                    <h2 className="mt-2">What Solutions We Provide</h2>
                </div>
                <div className="row g-4">
                    {services.map((service, index) => (
                        <div key={index} className={`col-lg-4 col-md-6 wow zoomIn`} data-wow-delay={service.delay}>
                            <div className="service-item-it d-flex flex-column justify-content-center text-center rounded">
                                <div className="service-icon flex-shrink-0">
                                    <i className={`fa ${service.icon} fa-2x`}></i>
                                </div>
                                <h5 className="mb-3">{service.title}</h5>
                                <p>{service.description}</p>
                                <Link className="btn px-3 mt-auto mx-auto" to={service.link}>Read More</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

            {/* Portfolio Start */}

            <div className="container-xxl py-2 ">
                <div className="container px-lg-5">
                    <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
                        {/* <h6 className="position-relative d-inline text-primary ps-4">Our Projects</h6> */}
                        <h2 className="mt-2">Recently Launched Our AI Tools</h2>
                    </div>
                    {/* <div className="row mt-n2 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="col-12 text-center">
                            <ul className="list-inline mb-5" id="portfolio-flters">
                                <li className={`btn px-3 pe-4 ${filter === "*" ? 'active' : ''}`} onClick={() => handleFilter("*")}>All</li>
                                <li className={`btn px-3 pe-4 ${filter === "first" ? 'active' : ''}`} onClick={() => handleFilter("first")}>Design</li>
                                <li className={`btn px-3 pe-4 ${filter === "second" ? 'active' : ''}`} onClick={() => handleFilter("second")}>Development</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row g-4 portfolio-container ">
                       
                        {filteredItems.map((item, index) => (
                            <div className="col-lg-4 col-md-6 portfolio-item wow zoomIn" data-wow-delay={`${index * 0.3 + 0.1}s`} key={index}>
                                <div className="position-relative rounded overflow-hidden">
                                    <img className="img-fluid w-100" src={item.image} alt={item.name} />
                                    <div className="portfolio-overlay">
                                        <a className="" to={item.image} data-lightbox="portfolio"></a>
                                        <div className="mt-auto">
                                            <small className="text-white"><i className="fa fa-folder me-2"></i>{item.category === "first" ? "Design" : "Development"}</small>
                                            <a className="h5 d-block text-white mt-1 mb-0" to="">{item.name}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>

            {/* videos */}

            <div className="container-xxl py-2  px-0 wow zoomIn" data-wow-delay="0.1s">
                <div className="row g-0">
                    <div className="col-md-6 bg-dark d-flex align-items-center">
                        <div className="p-5">
                            <h6 className="section-title-hotel text-start text-white text-uppercase mb-3">Linkometic</h6>
                            <h1 className="text-white mb-4">Our Linkometic App</h1>
                            <p className="text-white mb-4">
                                LiNKoMaTiC streamlines link building with AI-driven tools for bulk Google scraping, relevant site ranking, and efficient team management.
                                Tailored for agencies, it empowers link builders to work faster and more effectively.
                            </p>
                            <a href="https://linkomatic.ai/" target="_blank" className="btn btn-primary py-md-3 px-md-5 me-3">Read More</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="video_it">
                            <button type="button" className="btn-play" data-bs-toggle="modal" data-src="img/It/big_tool.mp4" data-bs-target="#videoModal">
                                <span></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content rounded-0">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Linkometic</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="ratio ratio-16x9">
                                    <video id="video" controls>
                                        <source src="img/It/big_tool.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Testimonial Start */}

           <div className="container-xxl bg-primary testimonial-it py-5 my-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container py-5 px-lg-5">
                <OwlCarousel className="owl-carousel testimonial-carousel" {...testimonial_carousel}>
                    {testimonials.map((testimonial, index) => (
                        <div key={index} className="testimonial-item bg-transparent border rounded text-white p-4">
                            <i className="fa fa-quote-left fa-2x mb-3"></i>
                            <p>{testimonial.text}</p>
                            <div className="d-flex align-items-center">
                                <img className="img-fluid flex-shrink-0 rounded-circle" src={testimonial.imgSrc} style={{ width: "50px", height: "50px" }} alt={testimonial.name} />
                                <div className="ps-3">
                                    <h6 className="text-white mb-1">{testimonial.name}</h6>
                                    <small>{testimonial.profession}</small>
                                </div>
                            </div>
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        </div>

            {/* team */}

            <div className="container-xxl py-5">
                <div className="container px-lg-5">
                    <div className="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">

                        <h2 className="mt-2">Meet Our Team Members</h2>
                    </div>
                    <div className="row g-4">
                        {teamMembers.map((member, index) => (
                            <div key={index} className={`col-lg-4 col-md-6 wow fadeInUp`} data-wow-delay={member.delay}>
                                <div className="team-item-it">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style={{ width: "75px" }}>
                                            <a className="btn btn-square text-primary bg-white my-1" href={member.social.facebook}><i className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square text-primary bg-white my-1" href={member.social.twitter}><i className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square text-primary bg-white my-1" href={member.social.instagram}><i className="fab fa-instagram"></i></a>
                                            <a className="btn btn-square text-primary bg-white my-1" href={member.social.linkedin}><i className="fab fa-linkedin-in"></i></a>
                                        </div>
                                        <div
                                            className="img-fluid rounded"
                                            style={{
                                                backgroundImage: `url(${member.imgSrc})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                width: '100%', // Adjust width as needed
                                                height: '425px' // Adjust height as needed
                                            }}
                                        ></div>
                                    </div>
                                    <div className="px-4 py-3">
                                        <h5 className="fw-bold m-0">{member.name}</h5>
                                        <small>{member.title}</small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        {/* <h6 className="section-title-hotel text-center text-primary text-uppercase">Contact Us</h6> */}
                        <h1 className="mb-5"><span className="text-primary text-uppercase">Contact</span> For Any Query</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="row gy-4">
                                <div className="col-md-4">
                                    <h6 className="section-title-hotel  text-start text-primary text-uppercase">Email Id</h6>
                                    <p><i className="fa fa-envelope-open text-primary me-2"></i>hr.roboanalytics@gmail.com
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <h6 className="section-title-hotel  text-start text-primary text-uppercase">Contact No</h6>
                                    <p><i className="fa fa-phone-alt text-primary me-2"></i>+91 6290 382 261</p>
                                </div>
                                <div className="col-md-4">
                                    <h6 className="section-title-hotel  text-start text-primary text-uppercase">Official Email Id</h6>
                                    <p><i className="fa fa-envelope-open text-primary me-2"></i>bhattacharjeegroup@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
                            <iframe className="position-relative rounded w-100 h-100"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14732.389228988559!2d88.4429931!3d22.612841200000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89f041e77c35b%3A0xcfa945e63f08fc75!2sRobo%20Analytics!5e0!3m2!1sen!2sin!4v1705593932787!5m2!1sen!2sin"
                                tabindex="0"></iframe>
                        </div>
                        <div className="col-md-6">
                            <div className="wow fadeInUp" data-wow-delay="0.2s">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: "150px" }}></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}
