import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default function Card() {
    return (
        <>
        <section className="card-area">
             <section className="card-section">
            {courseData.map((card, index) => (
               <div>
                    <div className='row' key={index}>
                        <div className='col-md-12'>
                            <div className="card">
                                <div className="flip-card">
                                    <div className="flip-card__container">
                                        <div className="card-front">
                                            <div className='card-front__tp card-front__tp'>
                                                <img src={card.image} alt={card.insideHeading} className="card-front__image" />
                                            </div>
                                            <div className="card-front__bt">
                                                <p className='card-front__text-view card-front__text-view'>
                                                    {card.title}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-back">

                                            <img className="" src={card.videoSrc} />

                                        </div>
                                    </div>
                                </div>
                                <div className="inside-page">
                                    <div className="inside-page__container">
                                        <h3 className='inside-page__heading inside-page__heading'>
                                            {card.insideHeading}
                                        </h3>
                                        <Link className="inside-page__text">
                                            <FaCheckCircle color='#212127' /> {card.course1}
                                        </Link>
                                        <Link className="inside-page__text">
                                            <FaCheckCircle color='#212127' /> {card.course2}
                                        </Link>
                                        <Link className="inside-page__text">
                                            <FaCheckCircle color='#212127' /> {card.course3}
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row' key={index}>
                        <div className='col-md-12'>
                            <div className="card">
                                <div className="flip-card">
                                    <div className="flip-card__container">
                                        <div className="card-front">
                                            <div className='card-front__tp card-front__tp'>
                                                <img src={card.image} alt={card.insideHeading} className="card-front__image" />
                                            </div>
                                            <div className="card-front__bt">
                                                <p className='card-front__text-view card-front__text-view'>
                                                    {card.title}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-back">

                                            <img className="" src={card.videoSrc} />

                                        </div>
                                    </div>
                                </div>
                                <div className="inside-page">
                                    <div className="inside-page__container">
                                        <h3 className='inside-page__heading inside-page__heading'>
                                            {card.insideHeading}
                                        </h3>
                                        <Link className="inside-page__text">
                                            <FaCheckCircle color='#212127' /> {card.course1}
                                        </Link>
                                        <Link className="inside-page__text">
                                            <FaCheckCircle color='#212127' /> {card.course2}
                                        </Link>
                                        <Link className="inside-page__text">
                                            <FaCheckCircle color='#212127' /> {card.course3}
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>



            ))}
              </section>
        </section>
        </>
    );
}

const courseData = [
    {
        image: "img/Institute/course1.jpg",
        title: "FULL STACK COURSES",
        course1: "MERN FULL STACK",
        course2: "PYTHON FULL STACK",
        course3: "PHP MYSQL FULL STACK",
        insideHeading: "FULL STACK COURSES",
        videoSrc: "img/Institute/course2.jpg"
    },
    {
        image: "img/Institute/course1.jpg",
        title: "FULL STACK COURSES",
        course1: "MERN FULL STACK",
        course2: "PYTHON FULL STACK",
        course3: "PHP MYSQL FULL STACK",
        insideHeading: "FULL STACK COURSES",
        videoSrc: "img/Institute/course2.jpg"
    },
    {
        image: "img/Institute/course1.jpg",
        title: "FULL STACK COURSES",
        course1: "MERN FULL STACK",
        course2: "PYTHON FULL STACK",
        course3: "PHP MYSQL FULL STACK",
        insideHeading: "FULL STACK COURSES",
        videoSrc: "img/Institute/course2.jpg"
    },
    {
        image: "img/Institute/course1.jpg",
        title: "FULL STACK COURSES",
        course1: "MERN FULL STACK",
        course2: "PYTHON FULL STACK",
        course3: "PHP MYSQL FULL STACK",
        insideHeading: "FULL STACK COURSES",
        videoSrc: "img/Institute/course2.jpg"
    },



];
